// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: rgba(68, 159, 238, 0.75); //background
  border: 1px double #fff;
  color: white; //text
}

.CalendarDay__selected_span:hover {
  background: rgba(68, 159, 238, 0.5); //background
  border: 1px double #fff;
  color: white; //text
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: rgb(68, 159, 238);
  border: 1px double #fff;

  color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: rgb(68, 159, 238);
  border: 1px double #fff;

  color: white;
}

//Will edit when the second date (end date) in a range of dates
//is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgba(68, 159, 238, 0.5);
  border: 1px double #fff;
  color: #fff;
}

.DateRangePickerInput_calendarIcon {
  padding: 7px;
  min-height: 38px;
}

.DateInput_input {
  color: #495057;
  font-size: 1rem;
  width: 100%;
}

.DateRangePickerInput__withBorder {
  border-radius: 4px;
  border: 1px solid #d5dce6;
  box-shadow: inset 0 2px 0 0 #f2f5f8;
}

.DateInput_input__focused {
  border-bottom: 2px solid rgb(68, 159, 238);
}

.DateRangePicker,
.DateRangePicker_1,
.DateRangePickerInput__withBorder {
  width: 100%;
}

.DateRangePicker_closeButton,
.DateRangePicker_closeButton_1,
.DayPicker_portal__vertical,
.DayPicker_portal__vertical_2 {
  margin-top: 50px;
}
